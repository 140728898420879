<template>
  <div class="bg_main full_h pb-5">
    <HeaderFormulir
      v-bind:date="date"
      v-bind:title="'Formulir Limbah Covid-19'"
      :id="id"
      :type="2"
    />
    <div class="d-flex justify-center">
      <div class="side_toolbar">
        <div>
          <div class="item_toolbar" @click="setupForm(true)">
            <v-progress-circular
              v-if="loadTempo"
              indeterminate
              color="#00b4cc"
            ></v-progress-circular>
            <v-icon color="#00b4cc" v-else large
              >mdi-content-save-outline</v-icon
            >
          </div>
        </div>
      </div>
      <div style="margin-top: 50px; width: 85%" class="pb-5">
        <div class="temp_itemtab">
          <v-stepper
            v-model="step"
            elevation="0"
            flat
            class="temp_stepper_header"
          >
            <div class="d-flex">
              <div
                :class="
                  step == 1
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                  1
                </div>
                <p class="ma-0 small_txt text-center">
                  Sumber Limbah PadatCovid-19
                </p>
              </div>
              <div
                :class="
                  step == 2
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                  2
                </div>
                <p class="ma-0 small_txt text-center">
                  Data Limbah Padat Covid-19
                </p>
              </div>
              <div
                :class="
                  step == 3
                    ? 'active_tab px-3 py-5 mr-2'
                    : 'header_tabs px-3 py-5 mr-2'
                "
              >
                <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                  3
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengolahan Limbah Padat Covid-19
                </p>
              </div>
              <div
                :class="
                  step == 4 ? 'active_tab px-3 py-5 ' : 'header_tabs px-3 py-5'
                "
              >
                <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                  4
                </div>
                <p class="ma-0 small_txt text-center">
                  Pengelolaan Limbah Cair Covid-19
                </p>
              </div>
            </div>

            <v-stepper-items>
              <!-- TAB 1 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="1" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="sumber_limbah"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Jumlah Pasien Covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Rata-rata jumlah pasien covid-19 di Fasyankes
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              sumber_limbah.jumlah_pasien_covid_19
                                .rata_rata_jumlah_pasien_covid_19_di_fasyankes
                                .dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">Orang/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Rata-rata jumlah pasien covid-19 di Isolasi
                            Terkendali (Isoter)
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            v-model="
                              sumber_limbah.jumlah_pasien_covid_19
                                .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_
                                .dataPenyusun_desc
                            "
                            type="number"
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">Orang/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 2 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="2" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat v-if="data_limbah">
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan limbah domestik covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah domestik
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah.timbulan_limbah_domestik_covid_19
                                .jumlah_limbah_domestik.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan Limbah medis Infeksius covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Infeksius
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah
                                .timbulan_limbah_medis__infeksius_covid_19
                                .jumlah_limbah_infeksius.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Tajam
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah
                                .timbulan_limbah_medis__infeksius_covid_19
                                .jumlah_limbah_tajam.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Farmasi
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah
                                .timbulan_limbah_medis__infeksius_covid_19
                                .jumlah_limbah_farmasi.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Patologi
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah
                                .timbulan_limbah_medis__infeksius_covid_19
                                .jumlah_limbah_patologi.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah Kimia
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number "
                            v-model="
                              data_limbah
                                .timbulan_limbah_medis__infeksius_covid_19
                                .jumlah_limbah_kimia.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan Limbah Isolasi Terpusat (Isoter)</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah infeksius
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah.timbulan_limbah_isolasi_terkendali__isoter_
                                .jumlah_limbah_infeksius.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Timbulan Limbah Vaksinasi Covid-19</b>
                    </div>
                    <v-row>
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Jumlah limbah vaksinasi
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            v-model="
                              data_limbah.timbulan_limbah_vaksinasi_covid_19
                                .jumlah_limbah_vaksinasi.dataPenyusun_desc
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">kg/hari</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- TAB 3 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="3" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat v-if="olah_limbah">
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <v-row>
                      <v-col cols="9">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Apakah mau menggunakan data yang sama dengan data
                            pengolahan limbah medis fasyankes?
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-radio-group
                          v-model="
                            olah_limbah.pengolah_limbah_medis_fasyankes
                              .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                              .dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Ketersediaan Alat Pengolah Limbah Di Fasyankes</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah terdapat alat pengolah limbah insinerator
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            :disabled="
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc == null ||
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc === '1'
                            "
                            v-model="
                              olah_limbah.ketersediaan_alat_pengolah_limbah
                                .apakah_terdapat_alat_pengolah_limbah_insinerator
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row class="mb-3">
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Kapasitas Operasional:
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            :disabled="
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc == null ||
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc === '1'
                            "
                            v-model="
                              olah_limbah.ketersediaan_alat_pengolah_limbah
                                .apakah_terdapat_alat_pengolah_limbah_insinerator
                                .additional_value
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">Kg/jam</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah terdapat alat pengolah limbah
                              <i>autoclave/microwave</i>
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.ketersediaan_alat_pengolah_limbah
                                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                                .dataPenyusun_desc
                            "
                            :disabled="
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc == null ||
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc === '1'
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row class="mb-3">
                      <v-col cols="4">
                        <div class="d-flex align-center" style="height: 100%">
                          <p class="ma-0 small_txt color_txt">
                            Kapasitas Operasional:
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-card
                          class="d-flex align-center pl-2 px-5"
                          outlined
                          style="width: max-content"
                        >
                          <input
                            type="number"
                            :disabled="
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc == null ||
                              olah_limbah.pengolah_limbah_medis_fasyankes
                                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                                .dataPenyusun_desc === '1'
                            "
                            v-model="
                              olah_limbah.ketersediaan_alat_pengolah_limbah
                                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                                .additional_value
                            "
                            class="pa-2 mr-1 custom_input"
                          />
                          <p class="ma-0 small_txt color_txt">Kg/jam</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                  <!-- -------------------------------------------------------SPESIFIKASI LIMBAH ----------------------------------------------------------->

                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Spesifikasi pengolahan limbah</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Diolah dengan insinerator
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .diolah_dengan_insinerator.dataPenyusun_desc
                            "
                            :disabled="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc == null ||
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc === '1'
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <div
                      class="d-flex align-center mb-4"
                      v-if="
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .diolah_dengan_insinerator.dataPenyusun_desc == '1' ||
                        olah_limbah.spesifikasi_pengolahan_limbah
                          .diolah_dengan_insinerator.dataPenyusun_desc == null
                      "
                    >
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <p class="ma-0 small_txt color_txt mr-2">No</p>
                        <input
                          type="number"
                          :disabled="
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.dataPenyusun_desc ==
                            null
                          "
                          v-model="
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.additional_nomer
                          "
                          class="pa-2 custom_input"
                        />
                      </v-card>
                      <div class="mx-3" style="width: 20px">
                        <v-divider></v-divider>
                      </div>
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <div class="marlef">
                          <p class="ma-0 small_txt color_txt">Tanggal Izin</p>
                        </div>

                        <v-menu
                          v-model="calendar1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          :disabled="
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.dataPenyusun_desc ==
                            null
                          "
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              type="text"
                              v-model="
                                olah_limbah.spesifikasi_pengolahan_limbah
                                .diolah_dengan_insinerator.additional_date
                              "
                              placeholder="mm/dd/yyyy"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="pa-2 custom_date"
                            />
                          </template>
                          <v-date-picker
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.additional_date
                            "
                            @input="calendar1 = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-icon>mdi-calendar</v-icon>
                      </v-card>
                    </div>
                    <div class="d-flex align-center mb-4" v-else>
                      <v-icon class="mr-2" small
                        >mdi-format-list-bulleted</v-icon
                      >
                      <p class="ma-0 small_txt color_txt">
                        Isi opsi jika tidak diolah dengan insinerator
                      </p>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah terdapat alat pengolah limbah
                              <i>autoclave/microwave</i>
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            row
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                                .dataPenyusun_desc
                            "
                            :disabled="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc == null ||
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc === '1'
                            "
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <div class="d-flex align-center mb-4">
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <p class="ma-0 small_txt color_txt mr-2">No</p>
                        <input
                          type="number"
                          :disabled="
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.dataPenyusun_desc ==
                              null ||
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.dataPenyusun_desc ==
                              '2'
                          "
                          v-model="
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                              .additional_nomer
                          "
                          class="pa-2 custom_input"
                        />
                      </v-card>
                      <div class="mx-3" style="width: 20px">
                        <v-divider></v-divider>
                      </div>
                      <v-card
                        class="d-flex align-center pl-2"
                        outlined
                        style="width: max-content"
                      >
                        <div class="marlef">
                          <p class="ma-0 small_txt color_txt">Tanggal Izin</p>
                        </div>
                        <v-menu
                          v-model="calendar2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          :disabled="
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.dataPenyusun_desc ==
                              null ||
                            olah_limbah.spesifikasi_pengolahan_limbah
                              .diolah_dengan_insinerator.dataPenyusun_desc ==
                              '2'
                          "
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              type="text"
                              v-model="
                                olah_limbah.spesifikasi_pengolahan_limbah
                                  .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                                  .additional_date
                              "
                              placeholder="mm/dd/yyyy"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="pa-2 custom_date"
                            />
                          </template>
                          <v-date-picker
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                                .additional_date
                            "
                            @input="calendar2 = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-icon>mdi-calendar</v-icon>
                      </v-card>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Bekerja sama dengan pengolah limbah medis
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .bekerja_sama_dengan_pengolah_limbah_medis
                                .dataPenyusun_desc
                            "
                            :disabled="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc == null ||
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc === '1'
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row>
                      <v-col cols="6">
                        <v-card class="d-flex align-center pl-2 mr-5" outlined>
                          <input
                            type="text"
                            :disabled="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .bekerja_sama_dengan_pengolah_limbah_medis
                                .dataPenyusun_desc == null ||
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .bekerja_sama_dengan_pengolah_limbah_medis
                                .dataPenyusun_desc == '2'
                            "
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .bekerja_sama_dengan_pengolah_limbah_medis
                                .additional_name_company
                            "
                            placeholder="Nama Perusahaan pengelola limbah sesuai dengan PKS"
                            class="pa-2 custom_long_input"
                          />
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <div class="d-flex align-center mb-4">
                          <v-card
                            class="d-flex align-center pl-2"
                            outlined
                            style="width: max-content"
                          >
                            <p class="ma-0 small_txt color_txt mr-2">No</p>
                            <input
                              type="number"
                              :disabled="
                                olah_limbah.spesifikasi_pengolahan_limbah
                                  .bekerja_sama_dengan_pengolah_limbah_medis
                                  .dataPenyusun_desc == null ||
                                olah_limbah.spesifikasi_pengolahan_limbah
                                  .bekerja_sama_dengan_pengolah_limbah_medis
                                  .dataPenyusun_desc == '2'
                              "
                              v-model="
                                olah_limbah.spesifikasi_pengolahan_limbah
                                  .bekerja_sama_dengan_pengolah_limbah_medis
                                  .additional_nomer
                              "
                              class="pa-2 custom_input"
                            />
                          </v-card>
                          <div class="mx-3" style="width: 20px">
                            <v-divider></v-divider>
                          </div>
                          <v-card
                            class="d-flex align-center pl-2"
                            outlined
                            style="width: max-content"
                          >
                            <div class="marlef">
                              <p class="ma-0 small_txt color_txt">
                                Tanggal Izin
                              </p>
                            </div>
                            <v-menu
                              v-model="calendar3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              :disabled="
                                olah_limbah.spesifikasi_pengolahan_limbah
                                  .bekerja_sama_dengan_pengolah_limbah_medis
                                  .dataPenyusun_desc == null ||
                                olah_limbah.spesifikasi_pengolahan_limbah
                                  .bekerja_sama_dengan_pengolah_limbah_medis
                                  .dataPenyusun_desc == '2'
                              "
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  v-model="
                                    olah_limbah.spesifikasi_pengolahan_limbah
                                      .bekerja_sama_dengan_pengolah_limbah_medis
                                      .additional_date
                                  "
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="pa-2 custom_date"
                                />
                              </template>
                              <v-date-picker
                                v-model="
                                  olah_limbah.spesifikasi_pengolahan_limbah
                                    .bekerja_sama_dengan_pengolah_limbah_medis
                                    .additional_date
                                "
                                @input="calendar3 = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-icon>mdi-calendar</v-icon>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Diolah dengan metode lainnya
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .diolah_dengan_metode_lainnya.dataPenyusun_desc
                            "
                            :disabled="
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc == null ||
                              olah_limbah.spesifikasi_pengolahan_limbah
                                .apakah_ingin_menggunakan_data_sebelumnya
                                .dataPenyusun_desc === '1'
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>

                  <!-- ------------------------------------------------------------------------------------------------------------------------------- -->
                  <v-card outlined class="pa-3 mb-4 rounded-max" v-if="olah_limbah.spesifikasi_pengolahan_limbah
                                .diolah_dengan_metode_lainnya.dataPenyusun_desc == '1'">
                    <div class="mb-3">
                      <b>Pengolahan limbah metode lainnya</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Disinfeksi untuk limbah infeksius padat
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.pengolahan_limbah_metode_lain
                                .disinfeksi_untuk_limbah_infeksius_padat
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Inertifikasi / solidifikasi untuk limbah farmasi
                              dan kimia padat
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.pengolahan_limbah_metode_lain
                                .inertifikasi_solidifikasi_untuk_limbah_farmasi_dan_kimia_padat
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Enkapsulasi untuk limbah tajam
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.pengolahan_limbah_metode_lain
                                .enkapsulasi_untuk_limbah_tajam
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Penguburan limbah patologi
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah.pengolahan_limbah_metode_lain
                                .penguburan_limbah_patologi.dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
              <!-- TAB 4 ----------------------------------------------------------------------------------------------------------------- -->
              <v-stepper-content step="4" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="olah_limbah_cair"
                >
                  <v-card outlined class="pa-3 mb-4 rounded-max">
                    <div class="mb-3">
                      <b>Metode pengolahan limbah cair</b>
                    </div>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah menggunakan metode Klorinasi
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah_cair.metode_pengolahan_limbah_cair
                                .apakah_menggunakan_metode_klorinasi
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah menggunakan metode Ultraviolet
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah_cair.metode_pengolahan_limbah_cair
                                .apakah_menggunakan_metode_ultraviolet
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card flat class="pa-2 rounded-lg mb-4" color="#F6F6F6">
                      <v-row>
                        <v-col cols="9">
                          <div class="d-flex align-center" style="height: 100%">
                            <p class="ma-0 small_txt color_txt">
                              Apakah menggunakan metode Ozon
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              olah_limbah_cair.metode_pengolahan_limbah_cair
                                .apakah_menggunakan_metode_ozon
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
        <div class="py-3 d-flex justify-center">
          <div class="d-flex align-center">
            <v-btn
              color="white"
              dark
              class="pa-3 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('prev')"
              ><v-icon small color="#00b4cc"
                >mdi-arrow-left-circle-outline</v-icon
              >
              <p class="ma-0 color_default ml-2">Kembali</p></v-btn
            >
            <div class="mx-5">
              <p class="ma-0 color_default">{{ step }}/4</p>
            </div>
            <v-btn
              v-if="step != 4"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="switchStep('next')"
            >
              <p class="ma-0 mr-2">Lanjut</p>
              <v-icon small>mdi-arrow-right-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="step == 4"
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow rounded-lg text-capitalize"
              @click="setupForm(false)"
            >
              <p class="ma-0 mr-2">Kirim</p>
              <v-icon small>mdi-tray-arrow-up</v-icon>
            </v-btn>
          </div>
        </div>
        <div>
          <success-alert v-bind:dialogSuccess="dialogSuccess" />
          <Alert
            v-bind:dialogAlert="dialogAlert"
            v-bind:temporary="temporary"
            v-bind:body="tobeSent"
            @close="dialogAlert = false"
            @save="savePermanent()"
          />
          <v-overlay
            :absolute="true"
            color="white"
          style="height: 100vh; position: fixed"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import HeaderFormulir from "../../../components/Formulir/HeaderFormulir.vue";
import Alert from "../../../components/Formulir/Modal/alert.vue";
import SuccessAlert from "../../../components/Formulir/Modal/successAlert.vue";
export default {
  components: { HeaderFormulir, Alert, SuccessAlert },
  computed: {
    ...mapState({
      formulir_covid: (state) => state.formulir.get_formulir_covid,
    }),
    sumber_limbah() {
      let data = null;
      if (this.formulir_covid) {
        data = this.formulir_covid.limbah_covid_19.sumber_limbah_padat_covid_19;
      }
      return data;
    },
    data_limbah() {
      let data = null;
      if (this.formulir_covid) {
        data = this.formulir_covid.limbah_covid_19.data_limbah_padat_covid_19;
      }
      return data;
    },
    olah_limbah() {
      let data = null;
      if (this.formulir_covid) {
        data =
          this.formulir_covid.limbah_covid_19.pengolahan_limbah_padat_covid_19;
      }
      return data;
    },
    olah_limbah_cair() {
      let data = null;
      if (this.formulir_covid) {
        data =
          this.formulir_covid.limbah_covid_19.pengelolaan_limbah_cair_covid_19;
      }
      return data;
    },
  },
  name: "formulirLimbahcoivd",
  data() {
    return {
      step: 1,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      date: "",
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    saveFormulir(body) {
      this.tobeSent = body;
      this.temporary = false;
      this.dialogAlert = true;
    },
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id,
      };
      console.log(body);
      this.$store
        .dispatch("formulir/getDataFormulirCovid", body)
        .then((data) => {
          this.date = data.data.date;
          this.loading = false;
        });
    },
    switchStep(state) {
      if (state == "next") {
        if (this.step < 4) {
          this.step++;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      } else {
        if (this.step > 1) {
          this.step--;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    },
    // update data
    temporarySave(body) {
      this.loadTempo = true;
      this.$store
        .dispatch("formulir/updateDataFormulirCovid", body)
        .then((data) => {
          console.log(data);
          // this.Swal("success", data.message);
          this.loadTempo = false;
          this.dialogAlert = true;
        })
        .catch((err) => {
          this.loadTempo = false;
          this.Swal("error", err.message);
          console.log(err);
        });
    },
    savePermanent() {
      this.dialogAlert = false;
      this.loading = true;
      this.$store
        .dispatch("formulir/saveDataFormulirCovid", this.tobeSent)
        .then((data) => {
          this.loading = false;
          this.dialogSuccess = true;
          setTimeout(() => {
            this.dialogSuccess = false;
            this.$router.push("/formulir");
          }, 1500);
          console.log(data);
        });
    },
    // SET UPPP FORMMMMM
    setupForm(draft) {
      let body = {
        report_id: this.id,
        data: [
          // form 1
          {
            name: "Rata-rata jumlah pasien covid-19 di-Fasyankes",

            value:
              this.sumber_limbah.jumlah_pasien_covid_19
                .rata_rata_jumlah_pasien_covid_19_di_fasyankes
                .dataPenyusun_desc,
          },
          {
            name: "Rata-rata jumlah pasien covid-19 di Isolasi Terkendali (Isoter)",

            value:
              this.sumber_limbah.jumlah_pasien_covid_19
                .rata_rata_jumlah_pasien_covid_19_di_isolasi_terkendali__isoter_
                .dataPenyusun_desc,
          },
          // form 2
          {
            name: "Jumlah limbah domestik",

            value:
              this.data_limbah.timbulan_limbah_domestik_covid_19
                .jumlah_limbah_domestik.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah Infeksius",

            value:
              this.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                .jumlah_limbah_infeksius.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah Tajam",

            value:
              this.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                .jumlah_limbah_tajam.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah Farmasi",

            value:
              this.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                .jumlah_limbah_farmasi.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah Patologi",

            value:
              this.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                .jumlah_limbah_patologi.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah Kimia",

            value:
              this.data_limbah.timbulan_limbah_medis__infeksius_covid_19
                      .jumlah_limbah_kimia.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah infeksius",

            value:
              this.data_limbah.timbulan_limbah_isolasi_terkendali__isoter_
                      .jumlah_limbah_infeksius.dataPenyusun_desc,
          },
          {
            name: "Jumlah limbah vaksinasi",

            value:
              this.data_limbah.timbulan_limbah_vaksinasi_covid_19
                .jumlah_limbah_vaksinasi.dataPenyusun_desc,
          },
          // form 3 ===========================================================
          {
            name: "Apakah mau menggunakan data yang sama dengan data pengolah limbah medis fasyankes",

            value:
              this.olah_limbah.pengolah_limbah_medis_fasyankes
                .apakah_mau_menggunakan_data_yang_sama_dengan_data_pengolah_limbah_medis_fasyankes
                .dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat alat pengolah limbah insinerator",

            value:
              this.olah_limbah.ketersediaan_alat_pengolah_limbah
                .apakah_terdapat_alat_pengolah_limbah_insinerator
                .dataPenyusun_desc,
            additional_value:
              this.olah_limbah.ketersediaan_alat_pengolah_limbah
                .apakah_terdapat_alat_pengolah_limbah_insinerator
                .additional_value,
          },
          // {
          //   name: "Kapasitas Operasional insinerator",
          //   value:
          //     this.olah_limbah.ketersediaan_alat_pengolah_limbah
          //       .kapasitas_operasional_insinerator.dataPenyusun_desc,
          // },
          {
            name: "Apakah terdapat alat pengolah limbah autoclave/microwave",

            value:
              this.olah_limbah.ketersediaan_alat_pengolah_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .dataPenyusun_desc,
            additional_value:
              this.olah_limbah.ketersediaan_alat_pengolah_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .additional_value,
            dataPenyusun_id:
              this.olah_limbah.ketersediaan_alat_pengolah_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .dataPenyusun_id,
          },
          // {
          //   name: "Kapasitas Operasional autoclave/microwave",

          //   value:
          //     this.olah_limbah.ketersediaan_alat_pengolah_limbah
          //       .kapasitas_operasional_autoclave_microwave.dataPenyusun_desc,
          // },
          // ===========================================================================
          {
            name: "Apakah Ingin menggunakan data sebelumnya",

            value:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .apakah_ingin_menggunakan_data_sebelumnya.dataPenyusun_desc,
          },
          {
            name: "Diolah dengan insinerator",

            value:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .diolah_dengan_insinerator.dataPenyusun_desc,
            additional_nomer:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .diolah_dengan_insinerator.additional_nomer,
            additional_date:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .diolah_dengan_insinerator.additional_date,
            dataPenyusun_id:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .diolah_dengan_insinerator.dataPenyusun_id,
          },
          // {
          //   name: "No insinerator",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah.no_insinerator
          //       .dataPenyusun_desc,
          // },
          // {
          //   name: "Tanggal izin insinerator",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah
          //       .tanggal_izin_insinerator.dataPenyusun_desc,
          // },
          {
            name: "Apakah Terdapat alat pengolah limbah autoclave/microwave",
            value:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .dataPenyusun_desc,
            additional_value: this.olah_limbah.spesifikasi_pengolahan_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .additional_value,
            additional_nomer:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .additional_nomer,
            additional_date:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .additional_date,
            dataPenyusun_id:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .apakah_terdapat_alat_pengolah_limbah_autoclave_microwave
                .dataPenyusun_id,
          },
          // {
          //   name: "No autoclave/microwave",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah
          //       .no_autoclave_microwave.dataPenyusun_desc,
          // },
          // {
          //   name: "Tanggal izin autoclave/microwave",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah
          //       .tanggal_izin_autoclave_microwave.dataPenyusun_desc,
          // },
          {
            name: "Bekerja sama dengan pengolah limbah medis",

            value:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .bekerja_sama_dengan_pengolah_limbah_medis.dataPenyusun_desc,
            additional_nomer:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .bekerja_sama_dengan_pengolah_limbah_medis.additional_nomer,
            additional_date:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .bekerja_sama_dengan_pengolah_limbah_medis.additional_date,
            additional_name_company:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .bekerja_sama_dengan_pengolah_limbah_medis
                .additional_name_company,
            dataPenyusun_id:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .bekerja_sama_dengan_pengolah_limbah_medis.dataPenyusun_id,
          },
          // {
          //   name: "Nama perusahaan pengola limbah sesuai dengan PKS",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah
          //       .nama_perusahaan_pengola_limbah_sesuai_dengan_pks
          //       .dataPenyusun_desc,
          // },
          // {
          //   name: "No PKS",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah.no_pks
          //       .dataPenyusun_desc,
          // },
          // {
          //   name: "Tanggal PKS",
          //   value:
          //     this.olah_limbah.spesifikasi_pengolahan_limbah.tanggal_pks
          //       .dataPenyusun_desc,
          // },
          {
            name: "Diolah dengan metode lainnya",

            value:
              this.olah_limbah.spesifikasi_pengolahan_limbah
                .diolah_dengan_metode_lainnya.dataPenyusun_desc,
          },
          // =================================================================================
          {
            name: "Disinfeksi untuk limbah infeksius padat",

            value:
              this.olah_limbah.pengolahan_limbah_metode_lain
                .disinfeksi_untuk_limbah_infeksius_padat.dataPenyusun_desc,
          },
          {
            name: "Inertifikasi/Solidifikasi untuk limbah farmasi dan kimia padat",

            value:
              this.olah_limbah.pengolahan_limbah_metode_lain
                .inertifikasi_solidifikasi_untuk_limbah_farmasi_dan_kimia_padat
                .dataPenyusun_desc,
          },
          {
            name: "Enkapsulasi untuk limbah tajam",

            value:
              this.olah_limbah.pengolahan_limbah_metode_lain
                .enkapsulasi_untuk_limbah_tajam.dataPenyusun_desc,
          },
          {
            name: "Penguburan limbah patologi",

            value:
              this.olah_limbah.pengolahan_limbah_metode_lain
                .penguburan_limbah_patologi.dataPenyusun_desc,
          },
          // step 4 ============================================================================
          {
            name: "Apakah menggunakan metode Klorinasi",

            value:
              this.olah_limbah_cair.metode_pengolahan_limbah_cair
                .apakah_menggunakan_metode_klorinasi.dataPenyusun_desc,
          },
          {
            name: "Apakah menggunakan metode Ultraviolet",

            value:
              this.olah_limbah_cair.metode_pengolahan_limbah_cair
                .apakah_menggunakan_metode_ultraviolet.dataPenyusun_desc,
          },
          {
            name: "Apakah menggunakan metode Ozon",

            value:
              this.olah_limbah_cair.metode_pengolahan_limbah_cair
                .apakah_menggunakan_metode_ozon.dataPenyusun_desc,
          },
        ],
      };
      if (draft) {
        this.temporarySave(body);
      } else {
        this.saveFormulir(body);
      }
    },

    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 25%;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 25%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
</style>